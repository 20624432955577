<script>
import { Bar, mixins } from 'vue-chartjs'
const { reactiveProp } = mixins

export default {
  extends: Bar,
  mixins: [reactiveProp],
  props: ['options'],
  mounted() {
    this.renderChart(this.chartData, this.options)
  },
  watch: {
    options(value, oldValue) {
      if (value.scales.xAxes[0].time.unit === oldValue.scales.xAxes[0].time.unit) return
      this.renderChart(this.chartData, this.options)
    }
  }
}
</script>
