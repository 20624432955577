<template>
  <v-menu ref="dateRangePicker" v-model="isDateRangePickerVisible" :close-on-content-click="false" :return-value.sync="dateRange" offset-y min-width="290px">
    <template v-slot:activator="datePickerScope">
      <div class="d-flex">
        <v-btn @click="onPrevSelectorClick" :disabled="isPrevSelectorDisabled" class="px-0" id="custom-disabled" min-width="48" min-height="48">
          <v-icon size="24">mdi-chevron-left</v-icon>
        </v-btn>

        <v-select
          ref="rangeSelect"
          v-model="currentDateRangeMode"
          :items="dateRangeModes"
          @change="setDateRange"
          return-object
          background-color="#f5f5f5"
          item-color="red"
          color="grey darken-2"
          class="mx-1"
          prepend-inner-icon="mdi-calendar-range"
          append-icon=""
          hide-details
          height="48"
          dense
          solo
          flat
        >
          <template v-slot:selection="{}">
            {{ selectorText }}
          </template>
          <template v-if="specialIntervals.length" v-slot:prepend-item>
            <v-expansion-panels accordion flat tile>
              <v-expansion-panel>
                <v-expansion-panel-header ripple class="px-4 py-0 body-2"> Специальные периоды </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <v-list-item
                    @click="onSpecialDateRangeClick(item)"
                    v-for="(item, index) in specialIntervals.slice().reverse()"
                    :key="index"
                    class="py-0 body-2"
                    dense
                  >
                    {{ item.title }}
                  </v-list-item>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
            <v-divider></v-divider>
          </template>
          <template v-slot:item="{ item }">
            <template v-if="item.value === 'picker'">
              <v-list-item @click="onDatePickerRequested" v-on="datePickerScope.on" v-bind="datePickerScope.attrs">
                <span class="body-2">{{ item.text }}</span>
              </v-list-item>
            </template>
            <template v-else-if="item.value === 'year'">
              <v-list-item class="px-0">
                <v-expansion-panels accordion flat tile>
                  <v-expansion-panel>
                    <v-expansion-panel-header ripple class="px-4 py-0 body-2"> Выберите год </v-expansion-panel-header>
                    <v-expansion-panel-content>
                      <v-list-item v-for="(item, index) in selectableYears" :key="index" @click="onYearClick(item)" class="py-0 body-2" dense>
                        {{ item }} год
                      </v-list-item>
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                </v-expansion-panels>
              </v-list-item>
            </template>
            <template v-else>
              <span class="body-2">{{ item.text }}</span>
            </template>
          </template>
        </v-select>
        <v-btn @click="onNextSelectorClick" :disabled="isNextSelectorDisabled" id="custom-disabled-2" class="px-0" min-width="48" min-height="48">
          <v-icon size="24">mdi-chevron-right</v-icon>
        </v-btn>
      </div>
    </template>
    <v-date-picker
      v-model="dateRange"
      @change="onDatePickerRangeChange"
      :first-day-of-week="1"
      :min="minDate"
      :max="currentDate"
      color="#e21a1a"
      class="mt-2"
      no-title
      range
      scrollable
    >
      <v-spacer />
      <v-btn text color="primary" @click="onDatePickerRangeCancel">Отмена</v-btn>
      <v-btn text color="primary" @click="onDatePickerRangeSave">OK</v-btn>
    </v-date-picker>
  </v-menu>
</template>
<script>
export default {
  props: {
    minDate: { type: String, required: true },
    specialIntervals: { type: Array, default: () => [] }
  },
  data() {
    const now = this.$moment()
    const month = this.$moment().format('MMMM')
    const lastMonth = now.subtract(1, 'month').format('MMMM')
    return {
      currentDate: null,
      dateRange: [null, null],
      dateRangeModes: [
        { text: 'Сегодня', value: 'day' },
        { text: 'Текущая неделя', value: 'week' },
        { text: lastMonth.replace(/^./, lastMonth[0].toUpperCase()), value: 'lastMonth' },
        { text: month.replace(/^./, month[0].toUpperCase()), value: 'month' },
        //{ text: this.$moment().format('YYYY год'), value: 'year' },
        { text: 'Выберите год...', value: 'year' },
        { divider: true },
        { text: 'С начала эксплуатации', value: 'full' },
        { divider: true },
        { text: 'Указать период...', value: 'picker' },
        { divider: true }
      ],
      currentDateRangeMode: { text: 'Сегодня', value: 'day' },
      isDateRangePickerVisible: false,
      useDateRangeText: false,
      showByMonths: false
    }
  },
  computed: {
    selectorText() {
      return this.useDateRangeText ? this.dateRangeText : this.currentDateRangeMode.text
    },
    selectableYears() {
      const years = []
      const dateStart = this.$moment(this.minDate)
      const dateEnd = this.$moment()
      while (dateEnd.diff(dateStart, 'years') >= 0) {
        years.push(dateStart.format('YYYY'))
        dateStart.add(1, 'year')
      }
      return years.reverse()
    },
    dateRangeText() {
      const dateFrom = this.$moment(this.dateRange[0])
      const dateTo = this.$moment(this.dateRange[1])
      return this.formatDateRangeText(dateFrom, dateTo)
    },
    isPrevSelectorDisabled() {
      const rangeMode = this.currentDateRangeMode.value
      if (rangeMode === 'day') {
        return this.dateRange[0] === this.minDate
      }
      if (rangeMode === 'week') {
        return this.$moment(this.dateRange[0]).format('YYYY-W') === this.$moment(this.minDate).format('YYYY-W')
      }
      if (rangeMode === 'month') {
        return this.$moment(this.dateRange[0]).format('YYYY-M') === this.$moment(this.minDate).format('YYYY-M')
      }
      if (rangeMode === 'year') {
        return this.$moment(this.dateRange[0]).year() === this.$moment(this.minDate).year()
      }
      return rangeMode === 'full'
    },
    isNextSelectorDisabled() {
      const rangeMode = this.currentDateRangeMode.value
      if (rangeMode === 'day') {
        return this.dateRange[1] === this.currentDate
      }
      if (rangeMode === 'week') {
        return this.$moment(this.dateRange[1]).format('YYYY-W') === this.$moment(this.currentDate).format('YYYY-W')
      }
      if (rangeMode === 'month') {
        return this.$moment(this.dateRange[1]).format('YYYY-M') === this.$moment(this.currentDate).format('YYYY-M')
      }
      if (rangeMode === 'year') {
        return this.$moment(this.dateRange[1]).year() === this.$moment(this.currentDate).year()
      }
      return rangeMode === 'full'
    }
  },
  methods: {
    setDateRange() {
      this.useDateRangeText = false

      const rangeMode = this.currentDateRangeMode.value
      if (['week', 'month'].includes(rangeMode)) {
        this.dateRange = [this.$moment().startOf(rangeMode).format('YYYY-MM-DD'), this.currentDate]
      } else if (rangeMode === 'day') {
        this.dateRange = [this.currentDate, this.currentDate]
      } else if (rangeMode === 'lastMonth') {
        this.dateRange = this.shiftRangePrev(this.$moment().startOf('month'), 'month')
      } else if (rangeMode === 'full') {
        this.dateRange = [this.minDate, this.currentDate]
      }

      this.showByMonths = ['year', 'full'].includes(rangeMode)
      this.$emit('change', this.dateRange, this.showByMonths)
    },
    formatDateRangeText(dateFrom, dateTo) {
      if (this.currentDateRangeMode.value === 'year') {
        return `${dateFrom.year()} год`
      }
      if (dateTo.diff(dateFrom) === 0) {
        if (dateFrom.year() === this.$moment(this.currentDate).year()) {
          return `${dateFrom.format('D MMMM')}`
        }
        return `${dateFrom.format('D MMMM YYYY')}`
      }
      let dateFromFormat, dateToFormat
      dateFromFormat = dateToFormat = 'D MMM YYYY'
      if (dateFrom.year() === dateTo.year()) {
        if (dateFrom.year() === this.$moment(this.currentDate).year()) {
          dateToFormat = 'D MMM'
        }
        if (dateFrom.month() === dateTo.month()) {
          dateFromFormat = 'D'
        } else {
          dateFromFormat = 'D MMM '
        }
      }

      return `С ${dateFrom.format(dateFromFormat)} по ${dateTo.format(dateToFormat)}`
    },
    onPrevSelectorClick() {
      const rangeMode = this.currentDateRangeMode.value
      const oldStartDate = this.$moment(this.dateRange[0])
      if (['week', 'month', 'year'].includes(rangeMode)) {
        this.dateRange = this.shiftRangePrev(oldStartDate, rangeMode)
      } else if (rangeMode === 'day') {
        const startDateString = oldStartDate.subtract(1, 'days').format('YYYY-MM-DD')
        this.dateRange = [startDateString, startDateString]
      } else if (rangeMode === 'lastMonth') {
        this.dateRange = this.shiftRangePrev(oldStartDate, 'month')
      }
      this.useDateRangeText = true
      this.$emit('change', this.dateRange, this.showByMonths)
    },
    shiftRangePrev(oldStartDate, shiftPeriod) {
      const startDate = oldStartDate.startOf(shiftPeriod).subtract(1, shiftPeriod)
      const endDate = startDate.clone().endOf(shiftPeriod)
      let startDateString = startDate.format('YYYY-MM-DD')
      let endDateString = endDate.format('YYYY-MM-DD')
      if (startDateString < this.minDate) startDateString = this.minDate
      return [startDateString, endDateString]
    },
    onNextSelectorClick() {
      const rangeMode = this.currentDateRangeMode.value
      const oldStartDate = this.$moment(this.dateRange[0])
      if (['week', 'month', 'year'].includes(rangeMode)) {
        this.dateRange = this.shiftRangeNext(oldStartDate, rangeMode)
      } else if (rangeMode === 'day') {
        const startDateString = oldStartDate.add(1, 'days').format('YYYY-MM-DD')
        this.dateRange = [startDateString, startDateString]
      } else if (rangeMode === 'lastMonth') {
        this.dateRange = this.shiftRangeNext(oldStartDate, 'month')
      }
      this.useDateRangeText = true
      this.$emit('change', this.dateRange, this.showByMonths)
    },
    shiftRangeNext(oldStartDate, shiftPeriod) {
      const startDate = oldStartDate.startOf(shiftPeriod).add(1, shiftPeriod)
      const endDate = startDate.clone().endOf(shiftPeriod)
      let startDateString = startDate.format('YYYY-MM-DD')
      let endDateString = endDate.format('YYYY-MM-DD')
      if (endDateString > this.currentDate) endDateString = this.currentDate
      return [startDateString, endDateString]
    },
    onSpecialDateRangeClick(interval) {
      this.$refs.rangeSelect.blur()
      const dateRangeStart = this.$moment(interval.start).format('YYYY-MM-DD')
      const dateRangeEnd = this.$moment(interval.end).format('YYYY-MM-DD')
      this.dateRange = [dateRangeStart, dateRangeEnd]
      this.useDateRangeText = true
      this.$emit('change', this.dateRange, this.showByMonths)
    },
    onYearClick(year) {
      this.$refs.rangeSelect.blur()
      this.currentDateRangeMode = { text: year + ' год', value: 'year' }
      const startDate = this.$moment({ year }).startOf('year').format('YYYY-MM-DD')
      const endDate = year === this.$moment().format('YYYY') ? this.currentDate : this.$moment({ year }).endOf('year').format('YYYY-MM-DD')
      this.dateRange = [startDate, endDate]
      this.setDateRange()
    },
    onDatePickerRequested() {},
    onDatePickerRangeChange() {
      if (this.dateRange[1] < this.dateRange[0]) {
        this.dateRange.reverse()
      }
    },
    onDatePickerRangeCancel() {
      this.isDateRangePickerVisible = false
    },
    onDatePickerRangeSave() {
      if (this.dateRange.length === 1) {
        this.dateRange.push(this.currentDate)
      }
      this.$refs.dateRangePicker.save(this.dateRange)
      this.useDateRangeText = true
      this.$emit('change', this.dateRange, this.showByMonths)
    }
  },
  mounted() {
    const today = this.$moment().format('YYYY-MM-DD')
    this.currentDate = today
    this.dateRange = [today, today]
    this.$emit('change', this.dateRange, this.showByMonths)
  }
}
</script>
<style scoped>
#custom-disabled.v-btn--disabled,
#custom-disabled-2.v-btn--disabled {
  background-color: #f5f5f5 !important;
}
.v-expansion-panel-header {
  min-height: 40px !important;
}
.v-expansion-panel-content >>> .v-expansion-panel-content__wrap {
  padding: 0 !important;
}
</style>
