export default {
  buildChartData(dataArray, checkedProductTypes, arePreOrdersShown) {
    const backgroundColor = ['#00529b', '#e6198d', '#9e7bb5', '#fdbb2f', '#f47a1f', 'red', '#00529b']
    const labels = []
    const data = []

    let totalSum = 0
    let totalCollect = 0
    const displayableGroups = [
      { chipCode: 0, syncCode: 1, money: 0, collect: 0, title: 'Билеты в парк' },
      { chipCode: 1, syncCode: 4, money: 0, collect: 0, title: 'Конные прогулки' },
      { chipCode: 2, syncCode: 3, money: 0, collect: 0, title: 'Экскурсии' },
      { chipCode: 3, syncCode: 101, money: 0, collect: 0, title: 'Фотоуслуги' },
      { chipCode: 4, syncCode: 2, money: 0, collect: 0, title: 'Товары' },
      { chipCode: 5, syncCode: 100, money: 0, collect: 0, title: 'Предзаказы' },
      { chipCode: 6, syncCode: 8, money: 0, collect: 0, title: 'Продукты питания' }
    ]

    const groups = dataArray !== null ? makeGroups(dataArray, arePreOrdersShown) : []
    displayableGroups.forEach((dGroup) => {
      groups.forEach((group) => {
        if (dGroup.syncCode === 101) {
          if (group.code === 5 || group.code === 7) {
            dGroup.money += group.money
            dGroup.collect += group.collect
          }
        } else if (dGroup.syncCode === group.code) {
          dGroup.money = group.money
          dGroup.collect = group.collect
        }
      })
    })

    displayableGroups.forEach((group) => {
      labels.push(group.title)
      if (Number.parseFloat(group.money) > 0) {
        if (checkedProductTypes.includes(group.chipCode)) {
          totalSum += group.money
          totalCollect += group.collect
          data.push(group.money.toFixed(2))
        } else {
          data.push(0)
        }
      } else data.push('')
    })

    return {
      data: {
        labels,
        datasets: [
          {
            backgroundColor,
            borderWidth: 0,
            data
          }
        ]
      },
      options: {
        cutoutPercentage: 80,
        elements: {
          center: {
            text: `Доход РЖД\n${formatPrice(totalCollect)} \u20bd\nиз ${formatPrice(totalSum)} \u20bd`,
            fontStyle: 'Roboto',
            maxFontSize: 28,
            lineHeight: 25
          }
        },
        tooltips: {
          callbacks: {
            label: function (tooltipItem, data) {
              var label = data.labels[tooltipItem.index]
              return label + ': ' + formatPrice(data.datasets[0].data[tooltipItem.index])
            }
          }
        },
        legend: {
          display: false
        },
        responsive: false,
        maintainAspectRatio: false
      }
    }
  }
}

function makeGroups(dataArray, arePreOrdersShown) {
  const groups = []
  makeGroup(groups, dataArray.services.items, dataArray.refundedServices.items)
  makeGroup(groups, dataArray.goods.items, dataArray.refundedGoods.items)
  if (arePreOrdersShown) makeGroup(groups, dataArray.preOrders.items, dataArray.refundedPreOrders.items)
  return groups
}

function makeGroup(groups, items, refundedItems) {
  items.forEach((it) => {
    const group = groups.find((group) => group.code === it.typeCode)
    if (group) {
      group.money += it.total.money
      group.collect += it.total.collect
    } else {
      groups.push({
        code: it.typeCode,
        money: it.total.money,
        collect: it.total.collect
      })
    }
  })
  refundedItems.forEach((it) => {
    const group = groups.find((group) => group.code === it.typeCode)
    if (group) {
      group.money -= it.total.money
      group.collect -= it.total.collect
    } else {
      groups.push({
        code: it.typeCode,
        money: it.total.money,
        collect: it.total.collect
      })
    }
  })
}

function formatPrice(price) {
  const parts = Number(price).toFixed(2).toString().split('.')
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
  if (parts[1] && parts[1] === '00') return parts[0]
  return parts.join('.')
}
