export default {
  buildChartData(dataArray, periods, checkedProductTypes, arePreOrdersShown, splitByMonth) {
    const colors = ['#00529b', '#e6198d', '#9e7bb5', '#fdbb2f', '#f47a1f', 'red']

    const displayableGroups = [
      { chipCode: 0, syncCode: 1, title: 'Билеты в парк', periodDataCollection: {} },
      { chipCode: 1, syncCode: 4, title: 'Конные прогулки', periodDataCollection: {} },
      { chipCode: 2, syncCode: 3, title: 'Экскурсии', periodDataCollection: {} },
      { chipCode: 3, syncCode: 101, title: 'Фотоуслуги', periodDataCollection: {} },
      { chipCode: 4, syncCode: 2, title: 'Товары', periodDataCollection: {} },
      { chipCode: 5, syncCode: 100, title: 'Предзаказы', periodDataCollection: {} }
    ]
    const groups = dataArray !== null ? makeGroups(dataArray, periods, arePreOrdersShown) : []
    displayableGroups.forEach((dGroup) => {
      dGroup.periodDataCollection = makePeriodDataCollection(periods)
      periods.forEach((period) => {
        groups.forEach((group) => {
          if (dGroup.syncCode === 101) {
            if (group.code === 5 || group.code === 7) {
              dGroup.periodDataCollection[period].money += group.periodDataCollection[period].money
              dGroup.periodDataCollection[period].collect += group.periodDataCollection[period].collect
            }
          } else if (dGroup.syncCode === group.code) {
            dGroup.periodDataCollection[period].money = group.periodDataCollection[period].money
            dGroup.periodDataCollection[period].collect = group.periodDataCollection[period].collect
          }
        })
      })
    })

    const periodDataCollection = makePeriodDataCollection(periods)
    periods.forEach((period) => {
      displayableGroups.forEach((group) => {
        if (checkedProductTypes.includes(group.chipCode)) {
          periodDataCollection[period].money += group.periodDataCollection[period].money
          periodDataCollection[period].collect += group.periodDataCollection[period].collect
        }
      })
    })

    const datasets = []
    datasets.push({
      label: 'Итого',
      barPercentage: 0.6,
      hidden: true,
      data: Object.entries(periodDataCollection).map(([, v]) => v.money)
    })
    datasets.push({
      label: 'Доход РЖД',
      barPercentage: 0.6,
      hidden: true,
      data: Object.entries(periodDataCollection).map(([, v]) => v.collect)
    })

    displayableGroups.forEach((group, index) => {
      let data = []
      if (checkedProductTypes.includes(group.chipCode)) {
        data = Object.entries(group.periodDataCollection).map(([, v]) => v.money)
      }
      datasets.push({
        label: group.title,
        backgroundColor: colors[index],
        barPercentage: 0.6,
        data
      })
    })

    return {
      data: {
        labels: periods,
        datasets
      },
      options: {
        legend: { display: false },
        scales: {
          xAxes: [
            {
              gridLines: { display: false },
              stacked: true,
              type: 'time',
              time: { unit: splitByMonth ? 'month' : 'day' }
            }
          ],
          yAxes: [
            {
              gridLines: { color: 'lightgray', zeroLineColor: 'lightgray' },
              stacked: true,
              ticks: {
                callback: function (value) {
                  return formatPrice(value)
                }
              }
            }
          ]
        },
        tooltips: {
          mode: 'label',
          callbacks: {
            footer: function (items, data) {
              return makeTooltipFooter(items[0].index, data)
            }
          }
        }
      }
    }
  }
}

function makeTooltipFooter(xIndex, data) {
  return 'Итого: ' + formatPrice(data.datasets[0].data[xIndex]) + '\nДоход РЖД: ' + formatPrice(data.datasets[1].data[xIndex])
}

function makeGroups(dataArray, periods, arePreOrdersShown) {
  const groups = []
  makeGroup(groups, periods, dataArray.services.items, dataArray.refundedServices.items)
  makeGroup(groups, periods, dataArray.goods.items, dataArray.refundedGoods.items)
  if (arePreOrdersShown) makeGroup(groups, periods, dataArray.preOrders.items, dataArray.refundedPreOrders.items)
  return groups
}

function makeGroup(groups, periods, items, refundedItems) {
  items.forEach((it) => {
    let group = groups.find((group) => group.code === it.typeCode)
    if (!group) {
      group = {
        code: it.typeCode,
        periodDataCollection: makePeriodDataCollection(periods)
      }
      groups.push(group)
    }
    Object.keys(it.periods).forEach((period) => {
      group.periodDataCollection[period].money += it.periods[period].money
      group.periodDataCollection[period].collect += it.periods[period].collect
    })
  })
  refundedItems.forEach((it) => {
    let group = groups.find((group) => group.code === it.typeCode)
    if (!group) {
      group = {
        code: it.typeCode,
        periodDataCollection: makePeriodDataCollection(periods)
      }
      groups.push(group)
    }
    Object.keys(it.periods).forEach((period) => {
      group.periodDataCollection[period].money -= it.periods[period].money
      group.periodDataCollection[period].collect -= it.periods[period].collect
    })
  })
}

function makePeriodDataCollection(periods) {
  const result = {}
  periods.forEach((it) => (result[it] = { money: 0, collect: 0 }))
  return result
}

function formatPrice(price) {
  const parts = Number(price).toFixed(2).toString().split('.')
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
  if (parts[1] && parts[1] === '00') return parts[0]
  return parts.join('.')
}
